import React from 'react';
import { Button, Typography, Stack, Grid } from '@mui/material';
import styled from '@emotion/styled';
import {
    green
} from '../theme/palette';
import SEO from '../components/seo';
import { isMobile } from 'react-device-detect';

const CssButton = styled(Button)({
    borderColor: 'white',
    color: 'white',
    opacity: 0.9,
    '&:hover': {
        opacity: 1,
        borderColor: 'white',
    },
    '&.Mui-disabled': {
        color: 'white',
        borderColor: 'white',
        opacity: '0.8'
    },
    '&.MuiButton-containedPrimary': {
        backgroundColor: 'white',
        boxShadow: 'none',
        color: green
    }
});

const ClaimPage = ({ location }): JSX.Element => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    console.log(token)

    if (isMobile) {
        return (
            <Grid
                container
                sx={{
                    backgroundColor: green,
                    height: "100vh"
                }}
                justifyContent={"center"}
                alignItems={"center"}
                alignContent={"center"}
            >
                {token !== null &&
                    <Stack
                        spacing={"32px"}
                    >
                        <Typography
                            variant="h1"
                            color="white"
                            textAlign={"center"}
                            sx={{
                                fontWeight: 400,
                                fontFamily: "Pacifico",
                            }}
                        >
                            Greens
                        </Typography>


                        < Typography
                            variant="h6"
                            color="white"
                            textAlign={"center"}
                            marginTop="64px"
                        >
                            Open in app to claim your greens card
                        </Typography>

                        <CssButton variant='outlined' href={`greens://claim?token=${token}`}>
                            Open app
                        </CssButton>

                    </Stack>
                }
            </Grid >
        );
    } else {
        return (
            <Grid
                container
                sx={{
                    backgroundColor: green,
                    height: "100vh"
                }}
                justifyContent={"center"}
                alignItems={"center"}
                alignContent={"center"}
            >
                < Typography
                    variant="h6"
                    color="white"
                    textAlign={"center"}
                    marginTop="64px"
                >
                    Please open on mobile device
                </Typography>
            </Grid>
        );
    }
};

export const Head = () => <SEO
    title="Claim Your Greens Card"
    imageUrl='https://greensgolf.app/claim-logo.png'
/>

export default ClaimPage;
